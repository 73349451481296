<template>
    <div class="space-y-4">
        <div>
            <input
                v-model="password"
                name="password"
                type="password"
                class="rounded-md shadow-sm border-gray-300 focus:border-navy-300 focus:ring focus:ring-navy-200 focus:ring-opacity-50"
            >
        </div>

        <div class="space-y-4 divide-y">
            <a
                href="#"
                class="hover:underline"
                @click.prevent="generatePassword"
            >Click here to auto-generate a password</a>

            <div
                v-if="autoPassword && (autoPassword === password)"
            >
                <p class="pt-4">
                    Auto generated password: {{ autoPassword }}
                </p>

                <input
                    type="hidden"
                    name="auto_password"
                    value="1"
                >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            password: null,
            autoPassword: null,
        };
    },
    methods: {
        generatePassword () {
            const value = Math.random().toString(36).slice(2);

            this.password = value;
            this.autoPassword = value;
        },
    },
};
</script>
