<template>
    <div>
        <slot />
    </div>
</template>
<script>
export default {
    data () {
        return {

        };
    },
    mounted () {
        if (this.$root.$refs && this.$root.$refs.role) {
            this.eventListeners();

            this.$root.$refs.role.dispatchEvent(new Event('change'));
        }
    },
    methods: {
        eventListeners () {
            this.$root.$refs.role.addEventListener('change', (e) => {
                const { target } = e;
                const selected = target.options[target.selectedIndex].text;

                this.$root.$refs.venues.classList.add('hidden');
                this.$root.$refs.partners.classList.add('hidden');

                if (selected === 'Affiliate') {
                    this.$root.$refs.partners.classList.remove('hidden');
                }

                if (selected === 'Venue') {
                    this.$root.$refs.venues.classList.remove('hidden');
                }
            });
        },
    },
};
</script>
