import Vue from 'vue';

import FileInput from './components/FileInput.vue';
import PasswordField from './components/PasswordField.vue';
import UserForm from './components/UserForm.vue';
import VenueOpeningHours from './components/Venue/OpeningHours.vue';

const Application = {};

Application.init = (callback) => {
    new Vue({
        components: {
            FileInput,
            UserForm,
            PasswordField,
            VenueOpeningHours,
        },
        data () {
            return {
                open: false,
            };
        },
        mounted () {
            if (typeof callback === 'function') {
                callback();
            }
        },
        methods: {},
    }).$mount('#site-wrapper');
};

export default Application;
