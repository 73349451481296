<template>
    <div class="sm:col-span-2 space-y-4">
        <div
            v-for="(row, index) in form.hours"
            :key="index"
            class="sm:grid sm:grid-cols-2 sm:gap-4"
        >
            <div class="mt-1 sm:mt-0 sm:col-span-1">
                <v-label
                    for="season_start"
                    class="mb-1 font-bold"
                >
                    Day(s)
                </v-label>
                <v-input
                    :id="`opening_hours_${index}_day`"
                    type="text"
                    :name="`opening_hours[${index}][day]`"
                    :value="row.day"
                    class="w-full"
                    :required="true"
                />
            </div>

            <div class="mt-1 sm:mt-0 sm:col-span-1">
                <v-label
                    for="season_start"
                    class="mb-1 font-bold"
                >
                    Time
                </v-label>

                <div class="flex space-x-4 items-center">
                    <v-input
                        :id="`opening_hours_${index}_time`"
                        type="text"
                        :name="`opening_hours[${index}][time]`"
                        :value="row.time"
                        class="w-full"
                        :required="true"
                    />

                    <div class="flex space-x-4 items-center">
                        <div class="flex space-x-2">
                            <a
                                :class="{ 'opacity-0 pointer-events-none': index === 0 }"
                                href="#"
                                @click.prevent="removeRow(index)"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    class="w-8 h-8"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </a>

                            <a
                                href="#"
                                @click.prevent="addRow(index)"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    class="w-8 h-8"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Input from '@/vue/components/Input.vue';
import Label from '@/vue/components/Label.vue';
import remove from 'lodash-es/remove';

export default {
    components: {
        'v-input': Input,
        'v-label': Label,
    },
    props: {
        hours: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            blankRow: {
                day: '',
                time: '',
            },
            form: {
                hours: [],
            },
        };
    },
    mounted () {
        this.initialize();
    },

    methods: {
        initialize () {
            if (this.hours.length) {
                this.form.hours = this.hours;
            } else {
                this.addRow();
            }
        },
        addRow () {
            this.form.hours.push(this.blankRow);
        },
        removeRow (index) {
            this.form.hours = remove(this.form.hours, (item, i) => i !== index);
        },
    },
};
</script>
