import each from 'lodash/each';
import FroalaEditor from 'froala-editor';
import flatpickr from 'flatpickr';

const Ui = {};

Ui.init = () => {
    Ui.links();
    Ui.wysiwygEditors();
    Ui.datepickers();
};

Ui.links = () => {
    const externalLinks = document.querySelectorAll('a.external, a[rel="external"], a[href$=".pdf"]');

    each(externalLinks, (el) => {
        el.addEventListener('click', (e) => {
            if (e.which !== 2) {
                window.open(e.currentTarget.href);
                e.preventDefault();
            }
        });
    });
};

Ui.wysiwygEditors = () => {
    const elements = document.querySelectorAll('.wysiwyg');

    if (!elements.length) {
        return;
    }

    // eslint-disable-next-line no-new
    new FroalaEditor('.wysiwyg');
};

Ui.datepickers = () => {
    const elements = document.querySelectorAll('.datepicker');

    if (!elements.length) {
        return;
    }

    each(elements, (el) => {
        flatpickr(el, {
            dateFormat: 'd-m-Y',
            minDate: el.dataset.minDate,
            maxDate: el.dataset.maxDate,
        });
    });
};

export default Ui;
