<template>
    <input
        :id="id"
        :type="type"
        :name="name"
        :value="value"
        class="rounded-md shadow-sm border-gray-300 focus:border-navy-300 focus:ring focus:ring-navy-200 focus:ring-opacity-50"
        :class="cssClass"
        :required="true"
    >
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        cssClass: {
            type: String,
            required: false,
            default: '',
        },
        required: {
            type: Boolean,
            required: false,
        },
    },
};
</script>
