<template>
    <div class="text-center space-y-8 file-upload">
        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div class="space-y-1 text-center">
                <svg
                    class="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                >
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <div class="flex text-sm justify-center text-gray-600">
                    <label
                        :for="`form_${name}`"
                        class="relative cursor-pointer bg-white rounded-md font-medium text-turquoise-600 hover:text-turquoise-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-turquoise-500"
                    >
                        <span>Upload a file</span>

                        <input
                            :id="`form_${name}`"
                            :name="name"
                            type="file"
                            class="sr-only"
                            @change="fileAdded"
                        >
                    </label>
                </div>
                <p class="text-xs text-gray-500">
                    PNG, JPG, GIF up to 2MB
                </p>
            </div>
        </div>

        <p
            v-if="fileName"
            class="w-full p-2 bg-gray-100 shadow rounded-lg text-sm"
            v-text="fileName"
        />
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            fileName: null,
        };
    },
    methods: {
        fileAdded (e) {
            this.fileName = this.basename(e.target.value);
        },
        basename (path) {
            return path.split('\\').reverse()[0];
        },
    },
};
</script>
