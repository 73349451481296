<template>
    <label
        class="block font-heading text-sm text-gray-700 mb-1 font-bold"
    >
        <slot />
    </label>
</template>
<script>
export default {
    //
};
</script>
